
import { defineComponent } from "vue";
import ToBeConfirmedData from "./ToBeConfirmedDataTemplate.vue";
import ToBeConfirmedDeductionData from "./ToBeConfirmedDeductionDataTemplate.vue";
import PendingApproveTemplate from "./PendingAppealTemplate.vue";

export default defineComponent({
  components: {
    ToBeConfirmedData,
    ToBeConfirmedDeductionData,
    PendingApproveTemplate,
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
    // ids: {
    //     type: Array,
    //     required: true,
    //     default: () => [],
    // },
    acceptType: {
      type: String,
      required: true,
      default: "",
    },
    tabType: {
      type: String,
      required: true,
      default: "",
    },
  },
  emits: ["close-modal", "confirm"],
  setup(props, { emit }) {
    const columns = [
      {
        title: "检查结果",
        dataIndex: "checkingResult",
      },
      {
        title: "申请数量",
        dataIndex: "claimCount",
      },
    ];
    const closeModal = () => {
      emit("close-modal");
    };
    const confirm = () => {
      emit("confirm");
    };

    return { columns, closeModal, confirm };
  },
});
