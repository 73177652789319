
import axios from 'axios';
import { defineComponent, ref, computed, watch } from 'vue';
import { CheckCircleOutlined } from '@ant-design/icons-vue';
import { FileItem } from '@/API/types';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import PreviewModal from './PreviewModal.vue';
import {
    editSupport,
    supportUpload,
    getAppealTypes,
    editAppealDoc,
    appealUpload,
    supportView,
    appealView,
} from '@/API/communication/appeal';
import { b64toBlob } from '@/utils/claim/b64toBlob';

//*
interface TabsData {
    appealFileId?: string | null;
    appealFileName?: string | null;
    appealFileRename?: string | null;
    isRequired: string | null;
    setupSupportingDocId: string | null;
    setupSupportingDocName: string | null;
    supportDocType: string | null;
    supportingDocId: string | null;
    supportingDocRename: string | null;
    doc?: Doc;
    echo?: any; //! 保存每个tab页下上传了的数据 用于回显
}

interface Doc {
    docName: string | null;
    docPath: string | null;
    docType: string | null;
    fileSize: number | null;
    id: string | null;
    is_watermark: string | null;
    serviceName: string | null;
    uploadTime: string | null;
    uploadUser: string | null;
    visibility: string | null;
}

interface UploadParams {
    vinNo: string | null;
    setupSupportingDocId: string | null;
    supportingDocType: string | null;
    uploadType?: string;
}

export default defineComponent({
    components: {
        CheckCircleOutlined,
        PreviewModal,
    },
    emits: ['close-upload-drawer', 'refresh'],
    props: {
        uploadDrawerVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        selectedClaim: {
            type: Object,
            required: true,
            default: function() {
                return {};
            },
        },
        drawerType: {
            type: String,
            required: true,
            default: '',
        },
    },
    /** 可能需要记录的属性值
     * isBeforeUpload
curUploadFile
curPercent
completeTips
uploadFileSize
isShowUploadBtn
isClickAbled

     */
    setup(props, { emit }) {
        console.log(props)
        const route = useRoute();

        const drawerType = computed(() => (props as any).drawerType);

        const appealType = ref<string | undefined>(undefined);
        const appealTypeList = ref([]);

        const isAppealEmpty = ref<boolean>(false);
        const selectAppeal = () => {
            isAppealEmpty.value = false;
        };
        const limitedLetters = ref<boolean>(false);
        const inputLetters = () => {
            limitedLetters.value = false;
        };

        const remarks = ref<string>('');
        const activeKey = ref<number>(0);
        const docTypeList = ref<TabsData[]>([]);
        const disableSubmit = ref(true);

        const getDocTypeList = () => {
            const params = {
                communicationId: (props as any).selectedClaim.id,
            };
            if (drawerType.value === 'upload') {
                editSupport(params).then((res: any) => {
                    docTypeList.value = res;
                    disableSubmit.value = false;
                });
            }
            if (drawerType.value === 'appeal') {
                editAppealDoc(params).then((res: any) => {
                    docTypeList.value = res.appealAndSupportingDocList;
                    remarks.value = res.appealComment || '';
                    appealType.value = res.appealType || undefined;
                    disableSubmit.value = false;
                });
            }
        };

        watch(
            () => {
                return (props as any).uploadDrawerVisible;
            },
            (newVal) => {
                if (newVal as boolean) {
                    disableSubmit.value = true;
                    getDocTypeList();
                }
            }
        );

        // 获取标题内容
        const title = computed(() => {
            if (drawerType.value === 'upload') {
                return `上传文档_`;
            } else {
                return `申诉_`;
            }
        });

        const isBeforeUpload = ref<boolean>(true);
        // 进度条
        const isShowUploadBtn = ref<boolean>(true);
        const curUploadFile = ref<object>({});
        const curPercent = ref<number>(0);
        const uploadFileSize = ref<string>('');
        const completeTips = ref<boolean>(false);

        // 导入控制按钮
        const isImport = ref<boolean>(false);
        // 文件上传
        const isClickAbled = ref<boolean>(true);
        const uploadComplete = ref<boolean>(true);
        const formData = new FormData();
        const emptyVisible = ref<boolean>(false);
        const emptyTipsText = ref<string>('');

        const reset = () => {
            isBeforeUpload.value = true;
            isShowUploadBtn.value = true;
            curPercent.value = 0;
            completeTips.value = false;
        };

        const uploadConditionReset = () => {
            if (docTypeList.value[activeKey.value].echo) {
                isBeforeUpload.value =
                    docTypeList.value[activeKey.value].echo.isBeforeUpload;
                curUploadFile.value =
                    docTypeList.value[activeKey.value].echo.curUploadFile;
                isShowUploadBtn.value =
                    docTypeList.value[activeKey.value].echo.isShowUploadBtn;
                curPercent.value =
                    docTypeList.value[activeKey.value].echo.curPercent;
                completeTips.value =
                    docTypeList.value[activeKey.value].echo.completeTips;
                uploadFileSize.value =
                    docTypeList.value[activeKey.value].echo.uploadFileSize;
                isClickAbled.value =
                    docTypeList.value[activeKey.value].echo.isClickAbled;
            } else {
                reset();
            }
        };

        // 设置抽屉显示状态
        const close = () => {
            emit('close-upload-drawer');
            appealType.value = undefined;
            remarks.value = '';
            reset();
        };

        // 上传前
        const beforeUpload = (file: FileItem) => {
            const FILE_TYPE = ['application/pdf', 'image/jpeg'];
            const isSelectedType = FILE_TYPE.includes(file.type as string);
            if (!isSelectedType) {
                message.error('请上传PDF或JPG格式文件！');
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error('请上传不超过5M的文件！');
            }
            return isSelectedType && isLt5M;
        };

        const uploadRequest = (source: any) => {
            if (formData.has('document')) {
                formData.delete('document');
            }
            formData.append('document', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value =
                source.file.size / 1024 > 1024
                    ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
                    : `${Math.floor(source.file.size / 1024)} KB`;
            isBeforeUpload.value = false;
        };

        // 上传文件
        const uploadFile = (source: any) => {
            // if (queryCheck()) return;
            isClickAbled.value = false;
            const url = '/claimapi/appeal/file/doc/upload';
            const params: UploadParams = {
                vinNo: (props as any).selectedClaim.vin,
                setupSupportingDocId:
                    docTypeList.value[activeKey.value].setupSupportingDocId,
                supportingDocType:
                    docTypeList.value[activeKey.value].supportDocType,
            };
            if (drawerType.value === 'appeal') {
                params.uploadType = 'appeal';
            }
            axios({
                method: 'post',
                data: formData,
                url,
                params,
            })
                .then((res: any) => {
                    isShowUploadBtn.value = false;
                    curPercent.value = 100;
                    completeTips.value = true;
                    isClickAbled.value = true;
                    docTypeList.value[activeKey.value].doc = res.data.data;
                    //! 保存每个tab页下上传了的数据 用于回显
                    const echo: any = {};
                    echo.isBeforeUpload = isBeforeUpload.value;
                    echo.curUploadFile = curUploadFile.value;
                    echo.curPercent = curPercent.value;
                    echo.completeTips = completeTips.value;
                    echo.uploadFileSize = uploadFileSize.value;
                    echo.isShowUploadBtn = isShowUploadBtn.value;
                    echo.isClickAbled = isClickAbled.value;
                    docTypeList.value[activeKey.value].echo = echo;
                })
                .catch((err) => {
                    isClickAbled.value = true;
                    console.log(err);
                });
        };

        const showUploadHandle = () => {
            isBeforeUpload.value = true;
            isShowUploadBtn.value = true;
            curPercent.value = 0;
            completeTips.value = false;
            window.sessionStorage.removeItem('errorFileName');
            isImport.value = false;
        };

        //? 预览
        const fileType = ref('');
        const base64code = ref('');
        const previewVisible = ref<boolean>(false);
        const showPreview = (docType: any, type: string) => {
            let view;
            let params;
            if (type === 'supporting') {
                params = {
                    supportingDocId: docType.supportingDocId,
                    supportingDocType: docType.supportDocType,
                };
                view = supportView;
            } else {
                params = {
                    appealFileId: docType.appealFileId,
                };
                view = appealView;
            }
            view(params).then((res: any) => {
                previewVisible.value = true;
                fileType.value = res.ext;
                if (res.ext === 'jpg' || res.ext === 'png') {
                    base64code.value =
                        'data:image/jpg;base64,' + res.base64code;
                } else if (res.ext === 'pdf') {
                    base64code.value = URL.createObjectURL(
                        b64toBlob(res.base64code, 'application/pdf')
                    );
                }
            });
        };

        const closePreview = () => {
            previewVisible.value = false;
        };

        const cancel = () => {
            close();
        };

        const genSupportDocList = () => {
            return docTypeList.value
                .filter((tab) => {
                    return tab.doc;
                })
                .map((selectedTab) => {
                    return {
                        docId: (selectedTab.doc as Doc).id,
                        docName: (selectedTab.doc as Doc).docName,
                        docPath: (selectedTab.doc as Doc).docPath,
                        docSize: (selectedTab.doc as Doc).fileSize,
                        docType: (selectedTab.doc as Doc).docType,
                        setupSupportingDocId: selectedTab.setupSupportingDocId,
                        supportingDocId: selectedTab.supportingDocId,
                        supportingDocType: selectedTab.supportDocType,
                    };
                });
        };

        const confirm = () => {
            const params = {
                communicationId: (props as any).selectedClaim.id,
                supportDocList: genSupportDocList(),
            };
            supportUpload(params).then((res: any) => {
                console.log(res);
            });
            close();
        };

        const save = () => {
            // 提交申诉后，该页面刷新数据
            let flag = false;
            if (remarks.value.length > 100) {
                limitedLetters.value = true;
                flag = true;
            }
            if (flag) return;
            const params = {
                communicationId: (props as any).selectedClaim.id,
                appealInfoList: genSupportDocList(),
                appealType: appealType.value,
                comment: remarks.value,
                operationType: 'submit',
            };
            appealUpload(params).then((_) => {
                close();
            });
        };

        const paramsCheck = () => {
            let flag = false;
            if (appealType.value === undefined) {
                isAppealEmpty.value = true;
                flag = true;
            }
            if (remarks.value.length > 100) {
                limitedLetters.value = true;
                flag = true;
            }
            return flag;
        };

        const checkVisible = ref(false);
        const uploadCheck = () => {
            if (curPercent.value === 100) {
                return false;
            } else {
                checkVisible.value = true;
                return true;
            }
        };

        const submitData = () => {
            const params = {
                communicationId: (props as any).selectedClaim.id,
                appealInfoList: genSupportDocList(),
                appealType: appealType.value,
                comment: remarks.value,
                operationType: 'submit',
            };
            appealUpload(params).then((_) => {
                close();
                emit('refresh');
                if (checkVisible.value) {
                    checkVisible.value = false;
                }
            });
        };

        const submit = () => {
            // 提交申诉后，该页面刷新数据
            if (paramsCheck()) return;
            if (uploadCheck()) return;
            submitData();
        };

        watch(drawerType, (newVal) => {
            if (newVal === 'appeal') {
                getAppealTypes().then((res: any) => {
                    appealTypeList.value = res;
                });
            }
        });

        return {
            //! ---- 预览
            previewVisible,
            closePreview,
            showPreview,
            //! ----
            //! ---- 文件类型tab页
            isAppealEmpty,
            limitedLetters,
            selectAppeal,
            inputLetters,
            remarks,
            appealType,
            appealTypeList,
            activeKey,
            docTypeList,
            uploadConditionReset,
            //! ----
            fileType,
            base64code,
            // loading,
            // downloadModalVisible,
            // downloadTipsText,
            // handleDownload,
            // handleCancel,
            // roundVal,
            title,
            disableSubmit,
            close,
            uploadFile,
            beforeUpload,
            uploadComplete,
            isBeforeUpload,
            isShowUploadBtn,
            uploadRequest,
            curPercent,
            curUploadFile,
            uploadFileSize,
            isImport,
            showUploadHandle,
            completeTips,
            isClickAbled,
            emptyVisible,
            emptyTipsText,
            cancel,
            confirm,
            save,
            submit,
            checkVisible,
            submitData,
        };
    },
});
